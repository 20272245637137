<!--  -->
<template>
  <div class="centerWrapper">
    <img
      src="../../assets/logo.png"
      style="width:6rem;height:6rem;margin-top:20px"
      class="centerLogo"
    />
    <div
      style="color:#535456;font-size:1.3rem;margin-bottom:1rem;margin-top:.3rem"
    >
      会议保障过程监管系统
    </div>
    <div style="color:#9D9FA1;font-size:.7rem;margin-bottom:1rem">
      您有如下身份访问该系统，请选择：
    </div>
    <div
      class="singleRoleOption"
      style="margin-bottom:10px"
      v-for="item in roleList"
      :key="item.id"
      @click="handleSelectRole(item)"
    >
      {{ item.roleName }}
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getCurrentUserAuthority, sendSelectedUserRole } from "@/api/user";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      roleList: []
    };
  },
  //监听属性 类似于data概念
  computed: {
    userRoles() {
      return this.$store.state.user.userRoles
        ? JSON.parse(this.$store.state.user.userRoles)
        : [];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleSelectRole(item) {
      //   保存选中角色及权限

      this.$store.commit("SetRoleId", item.roleId);
      let param = {
        pc: false,
        roleId: item.roleId
      };
      sendSelectedUserRole(param).then(res => {
        if (res.code === 10000) {
          this.$store.commit(
            "SetPageList",
            JSON.stringify(item.permissionList)
          );
          this.$store.commit("SetNavBarTitle", "界面选择");
          this.$router.push({ path: "/pageSelect" });
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
    getCurrentUserAuthority() {
      getCurrentUserAuthority().then(res => {
        if (res.code === 10000) {
          this.roleList = res.data.roleList;
          this.$store.commit("SetUserRole", JSON.stringify(res.data.roleList));
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getCurrentUserAuthority();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
</style>
